import FunctionBroadcastChannel from 'o365.modules.FunctionBroadcastChannel.ts';

const channel = new FunctionBroadcastChannel({
    id: 'o365-team-chats-navbar',
    functions: {
        'updateNavbarNotifications': () => {
            window.contextSelector.reloadChatsIndicator()
            return Promise.resolve('');
        }
    }
})

function notifyLastReadUpdate() {
    channel.execute('updateNavbarNotifications').catch(() => {
        // silent fail, no callback expected
    });
}

export { notifyLastReadUpdate };